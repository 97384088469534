
/* ----------  START GLOBAL STYLES   ---------- */
.warning-button {
    border-color: #faad14 !important;
    color: #faad14 !important;
}

.danger-ish-button {
    border-color: #ffbb96 !important;
    color: #fa541c !important;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }

  .ant-collapse {
      width: 100%;
  }
/* ----------  END GLOBALSTYLES   ---------- */

/* ----------  START CONFETTI BOMB STYLES   ---------- */
.confetti-bomb {
    font-size: 40px;
    position: fixed;
    cursor: pointer;
    right: 20px;
    bottom: 20px;
    z-index: 99;
    transition: all 0.2s ease;
}

.confetti-bomb:hover {
    transition: all 0.2s ease;
    font-size: 50px;
}

/* ----------  END CONFETTI BOMB STYLES   ---------- */

/* ----------  START PORTAL CONTAINER STYLES   ---------- */
.portal-container {
    min-height: 100vh;
}

.portal-container .portal-content {
    min-height: 100vh;
    padding: 15px;
    margin-left: 212px;
    background: white;
    margin-top: 60px;
}

/* ----------  END PORTAL CONTAINER STYLES   ---------- */


/* ----------  START TOP MENU STYLES   ---------- */
.top-menu-container {
    height: 50px;
    background: white;
    position: fixed;
    z-index: 999;
    top: 0px;
    left: 0px;
    width: 100%;
    box-shadow: 0 2px 8px #f0f1f2;
    padding: 12px 54px;

}

.top-menu-container .logo {
    width: 100px;
    height: 20px;
}

.top-menu-container .logout {
    position: absolute;
    right: 20px;
    top: 10px;

}

/* ----------  END TOP MENU STYLES   ---------- */

/* ----------  START SIDE MENU STYLES   ---------- */
.side-menu-container {
    height: 100vh;
    background: #f7f8f9;
    position: fixed;
    top: 49px;
    left: 0px;
}

.side-menu-container .greetings-container {
    padding-top: 12px;
    padding-bottom: 14px;
    background: white;
    text-align: center;
    border-right: 1px solid #e8e8e8
}

.side-menu-container .greetings-container .ant-typography {
    line-height: 29px;
}
.side-menu-mobile-shadow {
    display: none;
}
.menu-toggle-container {
    display: none;
}

/* ----------  END SIDe MENU STYLES   ---------- */

/* ----------  START LOADING CONTAINER STYLES   ---------- */
.loading-container {
    text-align: center;
    padding-top: 196px;
}

/* ----------  END LOADING CONTAINER STYLES   ---------- */

/* ----------  START LOGIN FORM STYLES   ---------- */
.authentication-global-wrapper {
    background: #f7f8f9;
    min-height: 100vh;
    background-size: cover !important;
    background-position-y: bottom !important;
}

.authentication-global-wrapper .login-form-wrapper {
    padding-top: 200px;
    padding-left: 20px;
    padding-right: 20px;
}

.authentication-global-wrapper .login-form-wrapper .ant-card {
    max-width: 340px;
    margin: auto;
    border-radius: 4px;
    padding-top: 20px;
    padding-bottom: 28px;
    box-shadow: 2px 3px 14px #e6e6e6;

}

.authentication-global-wrapper .login-form-wrapper .ant-card .login-logo {
    width: 150px;
    margin: auto;
    margin-bottom: 40px;
    height: 31px;
    text-align: center;
}

.authentication-global-wrapper .login-form-wrapper .ant-card .ant-card-meta-description {
    text-align: center;

    margin-top: 24px;
}



/* ----------  END LOGIN FORM STYLES   ---------- */

/* ----------  START CARD STYLES   ---------- */
.product-listing {
    display: none;
}

.cart-items .ant-card {
    min-height: 624px
}
.ant-card-cover  {
    text-align: center;
    height: 200px;
}
.ant-card-cover img {
    width: 210px;
    left: 0px;
    right: 0px;
    position: absolute;
    margin: auto;
}
/* ----------  END CARD STYLES   ---------- */

/* ----------  START COPYRIGHT STYLES   ---------- */
.copyright-tag {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    bottom: 14px;
    text-align: center;
}

/* ----------  END COPYRIGHT STYLES   ---------- */

/* ----------  START GLOBAL SEARCH STYLES   ---------- */
.global-search-container {
    position: absolute;
    left: 215px;
    z-index: 9999;
    top: 10px;
}

.global-search-container .search-wrapper {}

.global-search-container .results-panel {
    background: white;
    min-height: 100vh;
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    right: 0px;
    width: calc(100% - 213px);
    margin-top: 53px;
    padding: 20px;
    overflow-x: hidden;
    padding-bottom: 200px;
}

.global-search-container .results-panel .no-results-pane {

    text-align: center;
    padding-top: 100px;
}

.global-search-container .results-panel .no-results-pane i {
    font-size: 50px;

}

.global-search-container .results-panel .no-results-pane p {
    margin-top: 10px;
}

.global-search-container .results-panel .hightlight {
    background: #ffe58f;
}

.global-search-container .results-panel .ant-page-header {
    padding-left: 0px;
    padding-top: 0px;
}


.global-search-container .global-search-row .global-search-row-inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: baseline;
    align-content: flex-start;
}

.global-search-container .global-search-row .global-search-row-inner h5 {
    padding: 5px 10px;
    font-size: 16.5px;
    flex-basis: 100%;
}

.global-search-container .global-search-row .global-search-row-inner .global-search-result {

    flex-basis: 33.3%;
}

.global-search-container .global-search-row .global-search-row-inner .global-search-result a {
    cursor: pointer;
    font-size: 15px;
    margin-top: 5px;
}

.global-search-container .global-search-row .global-search-row-inner .global-search-result div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 1px 0px;
}

.global-search-container .global-search-row .global-search-row-inner .global-search-result div b {
    text-transform: capitalize;
    padding-right: 5px;
}

.global-search-container .global-search-row .global-search-row-inner .global-search-result div p {
    margin-bottom: 3px;
    font-size: 13px;
}
.ant-card-head-title {
    font-size: 13px !important;
}
/* ----------  END GLOBAL SEARCH STYLES   ---------- */


/* -------  START TABLE STYLES --------- */

.table-container div {
    vertical-align: baseline;
    font-size: 12px;
    letter-spacing: 0.4px;

}

.ReactTable {
    border: 0px;
}

.table-container .ReactTable * {
    vertical-align: baseline;
}

.table-container .ReactTable .rt-thead .rt-th,
.table-container .ReactTable .rt-thead .rt-td {
    border-bottom: 1px solid #e8e8e8;
    background: #fafafa;

}

.table-container .ReactTable .rt-thead .rt-resizable-header {
    border-bottom: 1px solid #e8e8e8;
}

.table-container .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: 1px solid #e8e8e8;
}

.table-container .ReactTable .-pagination .-pageJump input {
    border: 1px solid #e8e8e8;
    border-radius: 0px;
    border-radius: 3px;

}

.table-container .ReactTable .-pagination input,
.ReactTable .-pagination select {
    border-radius: 3px;
    border: 1px solid #e8e8e8;
    border-radius: 0px;
}


.table-container .ReactTable .rt-tbody .rt-tr-group:hover {
    background: #e6f7ff;
    /* transition: all 0.2s ease; */
}

.table-container .ReactTable .rt-tbody .rt-tr-group {
    /* transition: all 0.2s ease; */
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
    border-radius: 3px;
    transition: all 0.3s ease;
}

.ReactTable .rt-thead.-filters input:focus {
    border-color: #40a9ff;
    transition: all 0.3s ease;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
    border: 1px solid #e8e8e8;
    border-radius: 3px;
}

.ReactTable .rt-tbody .rt-td {
    border-right: 0px solid #e8e8e8;
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: 0px;
    padding-left: 5px;
}

/* -------  END TABLE STYLES --------- */

/* ----------  START DASHBOARD ROW STYLES   ---------- */
.dashboard-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    align-content: space-between;
    position: relative;
    overflow-x: hidden;
}

.dashboard-row .data-point-container {
    margin: 5px 15px 5px 0px;
    width: 120px;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: end;
    flex-direction: column;
    text-align: center;
    border-radius: 4px;
    padding: 11px;
    box-shadow: 0 2px 8px #f0f1f2;

}

.data-point-container img {
    width: 80%;
    margin: auto;
}

.data-point-container .ant-card-cover {
    height: 100px;
}

.dashboard-row .data-point-container .data .data-point {
    font-size: 22px;
    text-align: center;
    letter-spacing: 1px;

}

.dashboard-row .data-point-container .label {
    text-align: center;
    line-height: 10px;

}

.dashboard-row .ant-card-body {
    padding: 0px;
}

.dashboard-row .data-point-container .small-label {
    text-align: center;
    line-height: 13px;
    font-size: 9px;
}

.time-select-container {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 3;
}

.time-select-container select {
    padding: 5px;
}

.time-select-container p {
    float: left;
    margin-right: 10px;
    margin-top: 5px;
}

.date-range-select-container {
    position: absolute;
    right: 10px;
    top: 54px;
    z-index: 2;
    display: flex;
    /* flex-direction: column; */
}

/* ----------  END DASHBOARD ROW STYLES   ---------- */

/* -------  START CHART VIEW STYLES --------- */

.chart-row {
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 20px;
    align-content: space-between;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    height: fit-content !important;
}

.chart-container {
    margin-top: 10px;
}

.my-tooltip {
    background: transparent !important;
    background-color: transparent !important;
}

tspan {

    color: white;
    font-size: 14px !important;
}

/* -------  END CHART VIEW STYLES --------- */

/* -------  START MODAL STYLES --------- */
.modal-wrapper {
    padding-bottom: 40px;
}

.live-view-modal .ant-drawer-body {
    padding: 0px;
}

.live-view-modal .ant-drawer-body iframe {
    padding: 0px;
    border: 0px;
    min-height: calc(100vh - 60px);
    width: 100%;
}

.modal-wrapper .ant-divider-horizontal {
    margin: 7px 0px;
}

.bottom-bar {
    position: absolute;
    left: 0;
    z-index: 999;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
}

.modal-wrapper .main-header {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    display: block;
    margin-bottom: 12px;
}

.modal-wrapper .section-header {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    font-weight: 600;
    display: block;
    margin-bottom: 8px;
}

.modal-wrapper .ant-form-item {
    margin-bottom: 10px;
}

.modal-wrapper .delete-button {
    margin-top: 42px !important;
}

/* -------  END MODAL STYLES --------- */


/* -------  START MOBILE STYLES --------- */

@media only screen and (max-width: 768px) {
    .side-menu-container .greetings-container {
        display: none;
    }

    .portal-container .portal-content {
        margin-left: 0px;
    }

    .top-menu-container .logo {
        display: none;
    }

    .bottom-bar {
        bottom: 47px;
    }

    .modal-wrapper {
        padding-bottom: 80px;
    }

    .global-search-container {
        left: 10px;
    }

    .global-search-container .ant-input-affix-wrapper {
        width: 220px !important;
        margin-left: 40px;
    }

    .global-search-container .results-panel {
        width: 100%
    }

    .ant-drawer-body {
        padding: 5px !important;
    }

    input {
        font-size: 16px !important;
    }
    .menu-toggle-container {
        top: 10px;
        left: 10px;
        font-size: 20px;
        display: inline;
        color: #1890ff;
        z-index: 99999;
        position: fixed;
        cursor: pointer;
    }
    .side-menu-mobile-shadow {
        position: fixed;
        width: 100%;
        height: 100vh;
        display: inline;
        top: 50px;
        opacity: .7;
        background: black;
        z-index: 9999;
        cursor: pointer;
    }
    .side-menu-open {
        left: 0px;
    }
    .side-menu-closed {
        left: -220px;

    }
    .side-menu-container {
        z-index: 9999;
        position: fixed;
        transition: all 0.2s ease;
    }

}

/* -------  END MOBILE STYLES --------- */

.copy-and-paste-prompt {
    text-decoration: italic !important;
    font-size: 15px !important;

}

.copy-and-paste {
    padding: 20px;
    border: 2px solid black;
    border-radius: 3px;
}